<template>
<div id="head" class=" header-web flex header-web justify-between w-full h-24 bg-black  shadow-sm" data-aos="flip-down" >
  <div class="w-60 h-10  lg:ml-40 ml-7 cursor-pointer">
    <router-link to="/"><img :src="images.logo" alt="logo" original></router-link>
  </div>
  <nav class="md:ml-auto flex top flex-wrap items-center text-base justify-center">
    <router-link to="/"><a href="#" class="mr-5 text-xl top text-white hover:text-blueNew1">Accueil </a></router-link>      
    </nav>
  <div class="mt-6 mb-4 inline-flex lg:mr-40 mr-7">
    <button class="create-button p-6 h-12 rounded-full text-white text-lg flex justify-center items-center cursor-pointer bg-blueNew1"><a class="text-white" href="https://app-tagang.surge.sh/">Connexion</a>
    </button>
  </div>
</div>
  <Security></Security>
  <footer id="dark-theme" class='bottom-0 left-0 flex flex-col w-full gap-8 px-8 py-10 md:gap-12 bg-black'>
    <div class='grid grid-cols-2 gap-8 2xsm:grid-cols-2 md:grid-cols-3 '>
      <div class='flex flex-col gap-8'>
        <label class=' text-2xl'>
          <img :src="images.logoFooter" alt="logo" class="w-3/4" original> 
        </label>
        <div class='flex pl-12 text-white flex-col text-xl gap-6 '>contact@tagango.com</div>
        <div class='flex pl-12 text-white flex-col text-xl gap-6 '>+229 46 24 32 94</div>
        <div class='flex pl-12 text-white flex-col text-xl gap-6 '> Kindonou,Cotonou, Bénin</div>
        <div class='flex gap-4 p-2 pl-12 rounded-md social-wrapper'>
        <div class="flex justify-center space-x-2 text-gray-700">
  
    <!-- Behance -->
  
    <!-- Discord -->
  
    <!-- Facebook -->
    <a href="https://www.facebook.com/profile.php?id=100088829275144&sk=about"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-5 h-5 " style="color: #3b5998"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg> </a>
  
    <!-- Messenger -->
  
    <!-- Twitter -->
    <a href="https://twitter.com/bforprocess"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-5 h-5  " style="color: #00acee;"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"/></svg> </a>
  
    <!-- Google -->
  
    <!-- Instagram -->
    <a href="https://www.youtube.com/channel/UChU4hMiUyCoC3CCSUFzIk5A"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="w-7 h-7  " style="color: #ff0000;"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="currentColor" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/></svg></a>
  
    <!-- Linkedin -->
    <a href="https://www.linkedin.com/company/bforprocess/"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="w-5 h-5  " style="color:  #0e76a8;"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"/></svg> </a>
  
    <!-- Pinterest -->
  
    <!-- Vkontakte -->
  
    <!-- Stack overflow -->
  
    <!-- Telegram -->
  
    <!-- Youtube -->
  
    <!-- TikTok -->
  
    <!-- Snapchat -->
  
    <!-- Slack -->
  
    <!-- Github -->
  
    <!-- Dribbble -->
  
    <!-- Reddit -->
  
    <!-- Whatsapp -->
  
    <!-- Twitch -->
  
  </div>
      </div>
      </div>
      <div class='flex flex-col mt-12 gap-8'>
        <label class='text-2xl text-white font-bold'>Liens utiles</label>
        <ul class='flex flex-col gap-6'>
          <li>
            <router-link to="/"><a href="#" class=" mr-6 text-white text-semibold no-underline">Nos cartes</a></router-link>
          </li>
          <li>   
            <router-link to="/"><a href="#" class=" mr-6 text-white text-semibold no-underline">Comment ça marche?</a></router-link>
          </li> 
        </ul>
        <!-- <img :src="images.bannier" alt="logo" class="" original>  -->
      </div> 
      <div class='flex mt-12 flex-col gap-8'>
        <label class='text-2xl text-white font-bold'>S'abonner à notre newsletter</label>
        <div class='flex flex-col gap-2'>
        <p class='text-white'>Les dernières nouvelles, articles, ressources, envoyés à votre boîte de réception chaque semaine.</p>
      </div>
      <div class='flex flex-col gap-4 xsm:flex-row md:p-0'>
        <input class='px-4 py-3 text-lg transition-all bg-grayNew1 duration-300 rounded-lg focus:outline-none focus:right-1 ' placeholder='Renseigner votre email' type="text" />
        <button class='px-6 py-4 font-semibold bg-white hover:bg-grayNew1 rounded-lg'>S'abonner</button>
      </div>
      </div>
    </div>
    <div class='w-full h-px m-auto bg-gradient-to-r from-transparent via-gray-400 to-transparent'></div>
    <div class='flex flex-col center items-center '>
      <div class="text-xl text-white">
        &#169; 2024 Tag'n Go. Tous droits réservés. Proposé par <a href="https://bforprocess.com/">BforProcess</a>. 
      </div>
    </div>
  </footer>
</template>

<script>
import DropdownItem from '../../components/DropdownItem.vue';
import Header from '../../components/header/index.vue'
import Footer from '../../components/footer/index.vue'
import Security from '../../components/home/security.vue';
import logoFooter from '../../assets/images/logos.webp'
import scroll from '../../assets/images/sroll.png'
import bannier from '../../assets/images/baniere-payment.svg'

import logo from '../../assets/images/logos.webp'
export default {
name: "index",

components: {
DropdownItem,
Header,
Security,
Footer,

},
data() {
return {
  icons: {
  },
  images:{
    logo,
    logoFooter,
        scroll,
        bannier
  },
}
}
}


</script>

<style scoped>
@import "../../assets/styles/sass/variables.scss";
a {
color: white;
}



.footer {
    background: linear-gradient(to bottom right,);
    height: 50vh;
  }

</style>
